@import 'styles/texts';

.base {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  &.disabled {
    cursor: not-allowed;
  }

  &:hover:not(.disabled) {
    cursor: pointer;
  }
}

// global で a タグに対して text-decoration: underline を当てているので、打ち消す必要がある
a.base {
  text-decoration: none;
}

.primary {
  color: var(--primary-blue50);

  &:hover:not(.disabled),
  &:active:not(.disabled),
  &:focus:not(.disabled) {
    color: var(--primary-blue50);
  }

  &:hover:not(.disabled) {
    box-shadow: inset 0 -1px var(--primary-blue50);
  }

  &.disabled {
    color: var(--gray20-alpha);
  }

  &.underline {
    color: var(--primary-blue50);
    box-shadow: inset 0 -1px var(--primary-blue50);

    &.disabled {
      color: var(--gray20-alpha);
      box-shadow: none;
    }

    &:hover {
      box-shadow: none;
    }
  }
}

.normal {
  color: var(--gray100);

  &:hover:not(.disabled),
  &:active:not(.disabled),
  &:focus:not(.disabled) {
    color: var(--gray100);
  }

  &:hover:not(.disabled) {
    box-shadow: inset 0 -1px var(--gray100);
  }

  &.disabled {
    color: var(--gray20-alpha);
  }

  &.underline {
    color: var(--gray100);
    box-shadow: inset 0 -1px var(--gray100);

    &.disabled {
      color: var(--gray20-alpha);
      box-shadow: none;
    }

    &:hover {
      box-shadow: none;
    }
  }
}

.danger {
  color: var(--red100);

  &:hover:not(.disabled),
  &:active:not(.disabled),
  &:focus:not(.disabled) {
    color: var(--red100);
  }

  &:hover:not(.disabled) {
    box-shadow: inset 0 -1px var(--red100);
  }

  &.disabled {
    color: var(--gray20-alpha);
  }

  &.underline {
    color: var(--red100);
    box-shadow: inset 0 -1px var(--red100);

    &.disabled {
      color: var(--gray20-alpha);
      box-shadow: none;
    }

    &:hover {
      box-shadow: none;
    }
  }
}

.medium {
  @include typography('tight', 14px, normal);

  gap: 8px;

  & > svg {
    width: 21px;
    height: 21px;
  }
}

.primary.medium {
  @include typography('tight', 14px, bold);
}

.small {
  @include typography('tight', 12px, normal);

  gap: 4px;

  & > svg {
    width: 18px;
    height: 18px;
  }
}

.primary.small {
  @include typography('tight', 12px, bold);
}
